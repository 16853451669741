import React, { useMemo } from 'react';
import Head from 'next/head';

export const siteTitle = 'TubeKick - The Best YouTube Promotion Tool';
const defaultDescription =
    'With TubeKick you can increase your YouTube subscribers, views and more to give your channel a boost. Launch your first YouTube promotion today';
const SITE_URL =
    process.env.NODE_ENV === 'development'
        ? `http://localhost:${process.env.PORT || 3000}`
        : process.env.NEXT_PUBLIC_SITE_URL;

// const FACEBOOK_APP_ID = 'XXXXXXXXX';
const defaultImage = `${SITE_URL}/images/logo.png`;

export type MetaTagsProps = {
    titleDefault?: string;
    title?: string;
    description?: string;
    image?: string;
    contentType?: string;
    twitter?: string;
    noCrawl?: string;
    category?: string;
    published?: string;
    updated?: string;
    tags?: string;
    location?: Location;
    schema?: string;
};

const HeadCustom: React.FC<MetaTagsProps> = ({
    title,
    description,
    ...rest
}) => {
    const titlePage = 'Linepals';

    const desc = React.useMemo(
        () => description || defaultDescription,
        [description],
    );

    const logoHref = useMemo(() => {
        return `/images/logo-tiktok-pr.png`;
    }, []);

    return (
        <Head>
            <title>{titlePage}</title>
            <meta name="description" content={desc} />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, maximum-scale=1"
            />
            <meta
                property="og:image"
                content={rest.image ? rest.image : defaultImage}
            />
            <link rel="icon" href={logoHref} />
        </Head>
    );
};
export default HeadCustom;
