export const numberWithCommas: (value: string | number) => string = (value) =>
    new Intl.NumberFormat().format(Number(value));

export const formatNumber: (value: number | string) => string = (value) => {
    if (typeof value === 'string') {
        return parseFloat(value).toFixed(2);
    }
    return value.toFixed(2);
};

export const formatCurrency: (value: number) => string = (value) => {
    if (value === 0) return value.toString();
    return value?.toLocaleString('en', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    });
};

export const onScrollToElement = (ref: any): void => {
    if (ref.current && ref.current.scrollIntoView) {
        ref.current.scrollIntoView({
            alignTop: true,
            block: 'center',
            behavior: 'smooth',
        });
        setTimeout(() => {
            ref.current.focus();
        }, 500);
    }
};

export const convertBufferToParam = (artisInfo?: string | string[]) => {
    if (!artisInfo) {
        return null;
    }
    const itemInfoObj =
        Buffer.from(artisInfo.toString(), 'base64').toString('utf8') || '';
    return JSON.parse(itemInfoObj);
};

export const getDomainNumber = (domain?: string) => {
    if (domain.includes('app-staging.viewpals')) {
        return '1';
    }
    if (domain.includes('localhost')) {
        return '2';
    }
    if (domain.includes('linepals')) {
        return '3';
    }
    if (domain.includes('app-staging.streamsocial.co')) {
        return '4';
    }
    if (domain.includes('streamsocial.co')) {
        return '5';
    }
    if (domain.includes('app-staging.tiktribe.co')) {
        return '6';
    }
    if (domain.includes('tiktribe.co')) {
        return '7';
    }
    return '0';
};
